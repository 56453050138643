module.exports = [{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"fr","configPath":"/home/runner/work/Devfest2022/Devfest2022/locales/config.json","prefixDefault":false},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./locales","i18nextOptions":{"ns":["translation"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-D66NP8CLV0"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/favicon.jpg","icon_options":{"purpose":"any maskable"},"name":"Devfest Nantes 2022","short_name":"Devfest Nantes","start_url":"/","background_color":"#ffedbf","theme_color":"#ffedbf","display":"minimal-ui","description":"Informations générales sur le Devfest Nantes","lang":"fr","localize":[{"start_url":"/en/","lang":"en","name":"Devfest Nantes 2022","short_name":"Devfest Nantes","description":"General informations about the Devfest Nantes."},{"start_url":"/fr/","lang":"fr","description":"Informations générales sur le Devfest Nantes."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ecbbd2e346151f6fb3174e730ea5eccf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/Devfest2022/Devfest2022/src/layout/mdx.tsx"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/Devfest2022/Devfest2022","commonmark":false},
    }]
